<template>
  <!-- capture the show event modal -->
  <modal
    name="modal-sistema-agente"
    scrollable
    :width="modalSize"
    height="auto"
    @opened="onShow"
  >
    <Toast position="top-right" />
    <div style="padding: 32px" ref="modalContainer">
      <div class="buttons-container">
        <b-dropdown
          id="dropdown-1"
          :text="selectedSistemaAgente"
          v-if="navSelecionada === 1"
        >
          <b-dropdown-form
            class="dropdown-form d-flex justify-content-between w-100 pe-auto"
          >
            <div
              class="d-flex align-items-center justify-content-between h-100 w-100"
              @click="selectSistema"
            >
              <span style="width: 65px">Sistema</span>
            </div>
          </b-dropdown-form>
          <b-dropdown-form
            class="dropdown-form d-flex justify-content-between w-100 pe-auto"
          >
            <div
              class="d-flex align-items-center justify-content-between h-100 w-100"
              @click="selectAgente"
            >
              <span style="width: 65px">Agente</span>
            </div>
          </b-dropdown-form>
        </b-dropdown>
        <b-button
          v-if="navSelecionada !== 1"
          class="btn-agente-sistema"
          variant="success"
          style="
            background-color: #e4e6ef;
            border-color: #e4e6ef;
            color: #3f4254;
          "
          @click="navSelecionada = 1"
        >
          Listagem
        </b-button>
        <b-button
          v-if="this.checkSuperAdmin"
          href="https://s3.us-east-2.amazonaws.com/media.comunicante.net.br/agente/comunicante-agente.zip"
          class="b-button"
          variant="info"
        >
          Download Agente
        </b-button>
        <b-button
          v-if="navSelecionada !== 0"
          class="btn-agente-sistema"
          variant="success"
          @click="navSelecionada = 0"
        >
          Novo Agente
        </b-button>
        <b-button
          v-if="navSelecionada !== 2"
          class="btn-agente-sistema"
          variant="success"
          style="background: #ffa800; border-color: #ffa800"
          @click="redirectNewSystem"
        >
          Novo Sistema
        </b-button>
      </div>
      <br />
      <br />

      <Transition name="fade" appear :duration="{ enter: 500, leave: 800 }">
        <div v-if="navSelecionada == 0">
          <h2 class="text-center">Cadastrar Agente</h2>
          <br />
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
              label="Descrição"
              label-for="descricao-input"
              invalid-feedback="Descrição é necessária"
            >
              <b-form-input
                id="descricao-input"
                v-model="licDescricao"
              ></b-form-input>
              <!-- :state="validateState('lic_descricao')" -->
            </b-form-group>
            <b-form-group
              label="Status"
              label-for="status-select"
              invalid-feedback="Selecione um status"
            >
              <b-form-select
                id="status-select"
                v-model="licStatus"
                :options="['ATIVO', 'INATIVO', 'SUSPENSO']"
              >
                <!-- :state="validateState('lic_status')" -->
              </b-form-select>
            </b-form-group>

            <b-button
              class="btn-agente-sistema w-100"
              variant="success"
              @click="cadastrarAgente"
              >Salvar</b-button
            >
          </form>
        </div>
        <div v-else-if="navSelecionada == 1">
          <IndexLicenciada
            class="aut-camp-area"
            @atualizarDadosSistema="atualizarDadosSistema"
            v-if="selectedSistemaAgenteProp == 'sistema'"
            @editarLicenciada="editarLicenciada"
          />
          <IndexAgente
            class="aut-camp-area"
            v-if="selectedSistemaAgenteProp == 'agente'"
          />
        </div>
        <div v-else-if="navSelecionada == 2">
          <CadastroLicenciada
            :cadastro="cadastroLicenciada"
            :licenciadaSelecionadaId="licenciadaSelecionadaId"
            @cadastrarEditarLicenciada="atualizarListaLicenciada"
            @redirecionarParaCadastrarAgente="redirecionarParaCadastarAgente"
            @redirectSemMudancas="redirectSistemaSemMudancas"
          />
        </div>
      </Transition>
    </div>
  </modal>
</template>

<script>
import IndexLicenciada from "../licenciada/Index.vue";
import IndexAgente from "../agente/Index.vue";
import CadastroLicenciada from "../licenciada/CadastroLicenciada.vue";
import ApiService from "@/core/services/api.service";
import API_LINKS from "../api.links";
import Toast from "primevue/toast/Toast";
import { mapGetters } from "vuex";

export default {
  components: {
    IndexLicenciada,
    IndexAgente,
    CadastroLicenciada,
    Toast
  },
  data() {
    return {
      selectedSistemaAgenteProp: "sistema",
      navSelecionada: 1,
      licDescricao: "",
      licStatus: "",
      cadastroLicenciada: true,
      licenciadaSelecionadaId: null
    };
  },
  methods: {
    redirectNewSystem() {
      this.navSelecionada = 2;
      this.licenciadaSelecionadaId = null;
      this.cadastroLicenciada = true;
    },
    redirectSistemaSemMudancas() {
      this.navSelecionada = 1;
      this.$toast.add({
        severity: "warn",
        summary: "Sem mudanças",
        detail: "Não houve mudanças no cadastro.",
        life: 7000
      });
    },
    redirecionarParaCadastarAgente() {
      this.navSelecionada = 0;
    },
    onShow() {
      this.navSelecionada = 1;
    },
    atualizarListaLicenciada() {
      this.navSelecionada = 2;
      this.$emit("atualizarListagemDeCampanha");
      setTimeout(() => {
        this.navSelecionada = 1;
      }, 100);
    },
    editarLicenciada(licenciadaId) {
      this.cadastroLicenciada = false;
      this.licenciadaSelecionadaId = licenciadaId;
      this.navSelecionada = 2;
    },
    atualizarDadosSistema() {
      this.selectedSistemaAgenteProp == "sistema";
      this.selectedSistemaAgenteProp == "agente";
    },
    fecharModalAlterarSenha() {
      this.$modal.hide("modal-sistema-agente");
    },
    selectAgente() {
      this.selectedSistemaAgenteProp = "agente";
      this.$refs.modalContainer.click();
    },
    selectSistema() {
      this.$refs.modalContainer.click();
      this.selectedSistemaAgenteProp = "sistema";
    },
    validateState(name) {
      return;
    },
    cadastrarAgente() {
      this.licStatus = this.licStatus[0];
      ApiService.post(API_LINKS.licenca, {
        licenca: {
          lic_descricao: this.licDescricao,
          lic_status: this.licStatus
        }
      }).then((response) => {
        if (response.data["erro"] != "undefined") {
          this.$toast.add({
            severity: "success",
            summary: "Agente cadastrado",
            detail: "Você já pode usar o agente nos seus sistemas!",
            life: 7000
          });
        }
        this.navSelecionada = 1;
      });
    }
  },
  computed: {
    ...mapGetters(["checkSuperAdmin"]),
    modalSize() {
      if (window.screen.width > 900) return "1200";
      return "600";
    },
    selectedSistemaAgente() {
      if (this.selectedSistemaAgenteProp === "agente") {
        return "Agente";
      } else return "Sistema";
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.buttons-container {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  width: 100%;
  .btn-agente-sistema {
    width: 125px;
  }
  .dropdown-form {
    cursor: pointer;
  }
}
</style>
